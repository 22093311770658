function initSlideups() {
  $('.info-slideup').not('.not-animated').each(function() {
    var bot = parseInt($(this).outerHeight()) - parseInt($(this).find('.content-wrapper').css('padding-top')) - parseInt($(this).find('.pseudo-title').outerHeight(true));
    $(this).css('bottom',-bot);

    $(this).on('mouseenter',function(e) {
      if(!$(this).hasClass('in')) {
        $(this).addClass('in');
      }
    });

    $(this).on('mouseleave',function(e) {
      if($(this).hasClass('in')) {
        $(this).removeClass('in');
      }
    });
  });
}
