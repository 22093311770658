// Responsive breakpoints
var smBreakpoint = 768;
var mdBreakpoint = 992;
var lgBreakPoint = 1200;

$(function() {
  window.initInfoSlider();
  window.initInfoBlocks();

  window.initImageLinkSlider();
  window.initImageLinkBlocks();
  window.initImageZoomBlocks();


  initSlideups();
  initCloseOnBlackClick();
  window.initMenu();
  window.resizeContactBlock();
  window.resizeFooter();
  setLinkTargets();
  setTimeout(window.resizeGallery,25);
  initMapOverlay();

  swiper.init();

  $(window).on('resize',function() {
    window.initInfoSlider();
    window.initInfoBlocks();
    window.initImageLinkSlider();
    window.initImageLinkBlocks();
    window.initImageZoomBlocks();
    window.resizeContactBlock();
    window.resizeFooter();
    setTimeout(window.resizeGallery,25);
  });
});

function isTouchDevice() {
 return (('ontouchstart' in window)
      || (navigator.MaxTouchPoints > 0)
      || (navigator.msMaxTouchPoints > 0));
}

function isInBackend() {
  return ($('.neos-document-metadata').length>0 || $('#neos-application').length>0);
}
