var swiper = function($){
	'use strict';

	return {
		init: function () {
      var slider = $('.header-slider');

      if (isInBackend()) {
        var params = {
          prevButton: '.swiper-button-prev',
          nextButton: '.swiper-button-next',
          simulateTouch: false
        }
      } else {
        var params = {
          speed: 800,
          autoplay: 5000,
          autoplayDisableOnInteraction: true,
          simulateTouch: true
        }
      }


			var swiper = new Swiper(slider, params);
		}
	};

}(jQuery);
