window.resizeContactBlock = function() {
  $('.contact-block').each(function(e) {
    if($('.form-card button[type="submit"]').length) {
      var height = $(this).find('.form-card').outerHeight();
      //$(this).find('.info-cards').outerHeight(height);
    } else {
      setTimeout(resizeContactBlock,200);
    }
  });
}

window.resizeFooter = function() {
  var elem = $('footer .upper-row').children().not('.title');
  // elem = elem.add($('footer .upper-row').find('.align-bottom'));

  if($(window).outerWidth() > mdBreakpoint) {
    var max = 0;
    elem.each(function(e) {
      if($(this).outerHeight() > max) {
        max = $(this).outerHeight();
      }
    });
    elem.each(function(e) {
      $(this).css('height',max);
    });

    $('.align-bottom').each(function(e) {
      var parent = $(this).parent();
      parent.css('position','relative');
      parent.children('.align-bottom').each(function(i) {
        $(this).css('position','absolute');
        $(this).css('bottom','0px');
        if(i>0) {
          $(this).css('left','50%');
        } else {
          $(this).css('left','0px');
        }
      });
    });
  } else {
    elem.each(function(e) {
      $(this).css('height','');
    });

    $('.align-bottom').each(function(e) {
      var parent = $(this).parent();
      parent.css('position','');
      parent.children('.align-bottom').each(function(i) {
        $(this).css('position','');
        $(this).css('bottom','');
        $(this).css('left','');
      });
    });
  }
}

window.resizeGallery = function() {
  var max = 0;
  $('.gallery-item').each(function() {
    $(this).css('height','');

    if($(this).outerHeight() > max) {
      max = $(this).outerHeight();
    }
  });
  $('.gallery-item').each(function() {
    $(this).css('height',max);
  });
}
