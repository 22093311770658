window.initMenu = function() {
  $('.nav-button, .nav-button>.icon-bar').on('click',function(e) {
    if(!$('#navbar-collapse').hasClass('in')) {
      $('#navbar-collapse').addClass('in');
    } else {
      $('#navbar-collapse').removeClass('in');
    }
  });

  $('.menu-item.dropdown').off('mouseover').on('mouseover',function (e) {
    var elem = $(this);

    if($(window).innerWidth() > 768) {
      if(!elem.hasClass('open')) {
        $('.menu-item.dropdown').removeClass('open');
        elem.addClass('open');
      }
    } else {
      setTimeout(function () {
        $('.menu-item.dropdown').removeClass('open');
        elem.addClass('open');
      },10);
    }
  })
  $('.menu-item.dropdown').off('mouseout').on('mouseout',function (e) {
    e.stopImmediatePropagation();
    $(this).removeClass('open');
  });

  $('.dropdown-toggle').on('click',function (e) {
    e.preventDefault();
  })

  $(document).on('scroll',function(e) {
    if($(document).scrollTop() > 250) {
      $('nav.navbar').addClass('noopac').css('top','0px');
      $('nav.navbar').find('.dropdown-menu').addClass('noopac');
    } else {
      $('nav.navbar').removeClass('noopac').css('top','');
      $('nav.navbar').find('.dropdown-menu').removeClass('noopac');
    }
  });
  $(document).trigger('scroll');
}
