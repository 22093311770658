function setLinkTargets() {
  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position){
      position = position || 0;
      return this.substr(position, searchString.length) === searchString;
    };
  }

  var protocol = 'http://';
  var protocol2 = 'https://';
	var host = document.location.hostname;
	$('a').each(function() {
		if($(this).attr('href') !== undefined && $(this).attr('href') !== null) {
			if($(this).attr('href').startsWith(protocol) || $(this).attr('href').startsWith(protocol2)) {
				if($(this).attr('href').indexOf(host) >= 0) {
					$(this).attr('target','_self');
				} else {
					$(this).attr('target','_blank');
				}
			} else if($(this).attr('href').startsWith('/')) {
				$(this).attr('target','_self');
			} else if($(this).attr('href') === '') {
        $(this).removeAttr('href');
      }
		}
	});
}
