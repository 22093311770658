window.initInfoSlider = function() {
  window.infoSlider = [];
  var itemsPerView = 3;
  if($(window).outerWidth() < mdBreakpoint) {
    itemsPerView = 2;
  }
  if($(window).outerWidth() < smBreakpoint) {
    itemsPerView = 1;
  }

  $('.info-slider').each(function(index) {
    var options = {
      loop: true,
      prevButton: '.swiper-button-prev',
      nextButton: '.swiper-button-next',
      simulateTouch: true,
      slidesPerView: itemsPerView,
      speed: 400
    };

    if(isInBackend()) {
      $(this).addClass('swiper-no-swiping');
      options.loop = false;
      options.noSwiping = true;
    }

    if(window.infoSlider[index] === null || window.infoSlider[index] === undefined || typeof window.infoSlider[index] !== 'object') {
      window.infoSlider[index] = new Swiper($(this).get()[0],options);
      $(this).children('.contents').find('.swiper-slide').each(function(i) {
        window.infoSlider[index].appendSlide($(this).get()[0]);
      });
      $(this).children('.contents').remove();
    } else {
      window.infoSlider[index] = new Swiper($(this).get()[0],options);
      window.infoSlider[index].slidePrev(false,100);
    }
  });
}

function initInfoBlocks() {
  $('.info-block.animated').each(function(e) {
    var contentwrapper = $(this).find('.content-wrapper');
    var textwrapper = $(contentwrapper).find('.text-wrapper');
    var titlewrapper = $(contentwrapper).find('.title-wrapper');

    if($(window).outerWidth() > smBreakpoint) {
      var collapsed = -(parseInt(textwrapper.outerHeight()) + parseInt(textwrapper.css('margin-bottom')) + (parseInt(titlewrapper.css('margin-bottom')) / 2));
      contentwrapper.css('bottom',collapsed);

      $(this).off('mouseenter');
      $(this).on('mouseenter',function(e) {
        $(this).addClass('shown');
        $(this).one('mouseleave',function(e) {
          $(this).removeClass('shown');
        });
      });
    }
  });
}
