function initMapOverlay() {
  $('.google-map-canvas').wrap('<div class="google-map-wrapper"></div>').after('<div class="google-map-overlay"></div>');
  $('.google-map-wrapper')
    .css('position', 'relative')
    .on('mouseleave', function() {
      $(this).find('.google-map-overlay').show();
  });
  $('.google-map-overlay')
    .css({'position': 'absolute', 'top': '0', 'left': '0', 'width': '100%', 'height': '100%'})
    .on('click', function() {
      $(this).hide();
  })
}
