function initCloseOnBlackClick() {
  $(document.body).on('click touchend','#swipebox-slider .current img', function(e) {
    return false;
  }).on('click touchend','#swipebox-slider .current', function(e) {
    $('#swipebox-close').trigger('click');
  }).on('click touchend','#swipebox-bottom-bar #swipebox-arrows a', function(e) {
    return false;
  }).on('click touchend','#swipebox-bottom-bar', function(e) {
    $('#swipebox-close').trigger('click');
  }).on('click touchend','#swipebox-arrows', function(e) {
    $('#swipebox-close').trigger('click');
  });
}
